<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import { required , minLength } from "vuelidate/lib/validators";

    /**
     * Add-product component
     */
    export default {
        page: {
            title: "Create Work Area",
            meta: [
            {
                name: "description",
                content: appConfig.description,
            },
            ],
        },
        components: {
            Layout,
            PageHeader

        },
        mounted(){
            this.fetchLocations();
            // this.locations = this.$store.state.auth.currentUser.employee.locations;
        },
        watch:{
            multipleCount(value){
                if(value > 0 && value < 16){
                    // this.areaRoles = [];
                    for(var i = 0; i < value; i++){
                        this.areaRoles.push({
                            name: null,
                            payment : null
                        });
                    }
                }
                if(value == ''){
                this.areaRoles = [{name:null , payment : null}];
                }
            }
        },

        methods: {
            AddformData() {
                this.areaRoles.push({
                    name: null,
                    payment : null,
                  
                });
            },
            deleteRow(index) {
                // this.areaRoles.splice(index, 1);
                this.areaRoles.pop(); //removes the last item of the array
            },

            submitAreaRole() { 
                let self = this;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                } 
                if (self.processing) {
                    return;
                }
                self.processing = true;
                
                self.$axios.post('/area/store', {
                    area_name:self.area_name,
                    color:self.color,
                    location_id:self.location_id,
                    area_role:self.areaRoles    
                })
                .then(response => {
                    let data = response.data;
                    self.triggerSwal(data.message, "success");
                    self.$router.push("/workarea/listing");
                })
                .catch(error => {
                    self.handleErrorResponse(error.response, "error");
                })
                .finally(function () {
                    self.processing = false;
                });
            },
            fetchLocations() {
                 this.loading = true;
                this.$axios.get('/locations')
                .then(response => {
                    console.log(response.data);
                    let data = response.data.payload;
                    this.locations=data;
                    this.loading = false;
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(() => {
                     this.loading = false;
                });  
            },
        },
        data() {
            return {
              
                areaRoles: [{name:null , payment : null}],
                multipleCount: null,
                processing:false,
                title: "Work Area",
                items: [
                    {
                        text: "Area",
                    },
                    {
                        text: "Create Area",
                        active: true,
                    },
                ],
                locations:[],
                location_id:null,
                color: '#0a0a0a',
                area_name:null,
                i: null,
                loading:false
            };
        },
        validations:{
            area_name: {required},
            location_id: {required},
            areaRoles:{
                $each:{
                    name: {
                        required,
                    },
                    payment: {
                        required,
                    },
                }
            }
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title"/>
        <div class="row">
            <div class="card p-4">
                <b-form>
                    <div class="row" >
                        <div class="col-md-6">
                        <div class="col-md-12 mb-3">
                            <div class="d-flex align-items-center gap-2">
                                <div class="icon-wrapper mt-3 pt-1">
                                    <img src="@/assets/images/profile-manager/icon-11.png" width="21" alt="" />
                                </div>
                                <div class="form-field__wrapper w-100">
                                    <label>Name</label>
                                    <b-input-group class="">
                                        <b-form-input id="input-2" class="bg-white" v-model="area_name" placeholder="Enter Name Like Kitchen, BOH, FOH, Drive Thru" 
                                            :class="{'is-invalid':$v.area_name.$error}" required></b-form-input>
                                        <b-input-group-prepend is-text class="w-25" >
                                            <b-form-input id="color" type="color" v-model="color" ></b-form-input>
                                        </b-input-group-prepend>
                                        <div v-if="$v.area_name.$error" class="invalid-feedback" >
                                            <span v-if="!$v.area_name.required">Name is required.</span>
                                        </div>
                                    </b-input-group>
                                </div>
                            </div>
                        </div>
                                <div class="col-md-12 mt-3" v-for="(role,index) in areaRoles" :key="index">
                                
                                    <div class="d-flex align-items-center gap-2">
                                        <div class="icon-wrapper mt-3 pt-1">
                                            <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                        </div>
                                        <div class="form-field__wrapper col-md-6">
                                            <b-form-group id="example-date" label="Role/Position" label-for="role">
                                                <b-form-input id="role" v-model="role.name" placeholder="Add Role/Position Like bagger, stuffer, iPad, breading," type="text"
                                                :class="{'is-invalid':$v.areaRoles.$each[index].name.$error}" ></b-form-input>
                                                <div v-if="$v.areaRoles.$each[index].name.$error" class="invalid-feedback" >
                                                    <span v-if="!$v.areaRoles.$each[index].name.required">Role/Position is required.</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="form-field__wrapper col-md-5">
                                            <div class="input-group mt-4">
                                                <span class="input-group-text">$</span>
                                                <input id="WageRate" v-model="role.payment" placeholder="Add Wage Rate Per Role" type="number"
                                                :class="{'is-invalid':$v.areaRoles.$each[index].payment.$error}" class="form-control" />
                                                <div v-if="$v.areaRoles.$each[index].payment.$error" class="invalid-feedback" >
                                                    <span v-if="!$v.areaRoles.$each[index].payment.required">Wage Rate is required.</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                        
                            <div class="col-md-12 mt-3">
                                <div class="d-flex justify-content-between">
                                    <div class="delete-fields ms-4" v-if="areaRoles.length>1">
                                        <i class="fas fa-trash f-12"> </i>
                                        <input type="button" class="mt-3 mt-lg-0 border-0 bg-white" value="Delete" @click="deleteRow(areaRoles.length)" />
                                    </div>
                                    <div class="add-fields">
                                        <i class="fas fa-plus f-12"> </i>
                                        <input type="button" class="mt-3 mt-lg-0 border-0 bg-white" value="Add Multi" @click="AddformData" />
                                    </div>
                                </div>
                            </div>
                        
                    
                    </div>

                <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
                <div class="col-md-3">      
                <div>
                    <label>Select Location</label>
                    <div class="">
                        <select v-model="location_id" id="emp_job_type" class="form-select" :class="{'is-invalid':$v.location_id.$error}">
                            <option :value="null" v-if="locations.length == 0" disabled>No Location Found</option>
                                            <option :value="null" v-else disabled>Select</option>
                            <option :value="location.id" v-for="(location,index) in locations" :key="index" >{{location.name}}</option>
                        </select>
                        <div v-if="$v.location_id.$error" class="invalid-feedback" >
                            <span v-if="!$v.location_id.required">Location is required.</span>
                        </div>
                    </div>
                </div>               
                

                <div class="form-field__wrapper w-100 mt-4">
                    <b-form-group id="example-date" label="Count of Multiple Role to Add" label-for="Count_MultipleRole">
                        <b-form-input id="Count_MultipleRole" placeholder="Add Count Like 5 , 10 , 20 ..." v-model="multipleCount" type="text"></b-form-input>
                        <span v-if="multipleCount > 15" class="f-12 text-danger"> Counts Limit Exceed</span>
                    </b-form-group>
                </div>
                    
                </div>
                    </div>
                      
                    <div class="col-md-12 mt-3 text-end">
                  <SubmitButton :processing="processing" text="Create" @clicked="submitAreaRole" />
                        
                    </div>
                </b-form>
            </div>
        </div>
    </Layout>
</template>

<style scoped>
.input-group-text{
    height: 40px !important;
}
</style>
